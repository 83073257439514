<template>
  <div class="impot-societes-list">
    <PageHeader
      title="Calculateurs d'impôt societe"
      :has-actions="true"
      :has-back="true"
      @back="goBack">
      <md-button
        class="md-primary md-raised"
        @click="$router.push('/admin/calculators/impot-societes/create')">
        Ajouter un impôt revenu
      </md-button>

      <md-button
        class="md-primary md-raised"
        @click="$router.push('/admin/calculators/impot-societes/history')">
        Histoire
      </md-button>
    </PageHeader>

    <!-- La liste des calculators -->
    <sortable-list
      link="/admin/calculators/impot-societes/update/"
      :items="data"
      :parameters="listParameters"
      :hasPagination="true">
    </sortable-list>
  </div>
</template>

<script>
    import calculatorsModel from "../../../../model/calculators";
    import PageHeader from "@/components/PageHeader";

    export default {
        components : {
            PageHeader,
        },
        data() {
            return {
                isDebug        : false,
                data           : [],
                listParameters : [
                    {
                        name : "Montant min",
                        key  : "montant_min",
                    },
                    {
                        name : "Montant max",
                        key  : "montant_max",
                    },
                    {
                        name : "Coef bas",
                        key  : "coef_bas",
                    },
                    {
                        name : "Coef haut",
                        key  : "coef_haut",
                    },
                ],
            };
        },
        methods    : {
            goBack() {
                this.$router.push("/admin/calculators/");
            },
        },
        mounted() {
            calculatorsModel.getAllSocietes().then(data => {
                this.data = data;
            });
        },
    };
</script>

<style lang="scss" scoped>
  .impot-societes-list {
    .debug-log {
      max-height: 200px;
      overflow-y: scroll;
    }
  }
</style>
